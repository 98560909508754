<template>
  <div
    :class="['bg-white', 'py-3', 'flex', 'items-center', 'justify-between', 'border-t', 'border-gray-200', 'rounded-b-md',  {'shadow': shadow, 'px-4 sm:px-6': !publicPage, 'px-0 border-none pt-0': publicPage}]"
    aria-label="Pagination"
  >
    <div class="hidden sm:block">
      <p class="text-sm text-gray-600">
        Showing
        <span v-if="totalPages > 1">
          <span class="font-medium">{{beginNumber()}}</span>
          to
          <span class="font-medium">{{middleNumber()}}</span>
          of
        </span>
        <span class="font-medium">{{total}}</span>
        {{total == 1 ? 'result' : 'results'}}
      </p>
    </div>
    <div class="flex-1 flex space-x-3 justify-between sm:justify-end">
      <button
        type="button"
        class="btn btn-xs btn-white"
        @click="onClickPreviousPage"
        :disabled="isInFirstPage"
      >Previous</button>
      <button
        type="button"
        class="btn btn-xs btn-white"
        @click="onClickNextPage"
        :disabled="isInLastPage"
      >Next</button>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      btnColor: {
        type: String,
        default: "btn-indigo",
      },
      maxVisibleButtons: {
        type: Number,
        required: false,
        default: 3,
      },
      totalPages: {
        type: Number,
        required: true,
      },
      total: {
        type: Number,
        required: true,
      },
      perPage: {
        type: Number,
        required: true,
      },
      currentPage: {
        type: Number,
        required: true,
      },
      publicPage: {
        type: Boolean,
        default: false,
      },
      shadow: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      startPage() {
        if (this.currentPage === 1) {
          return 1;
        }
        if (this.currentPage === this.totalPages) {
          return this.totalPages - this.maxVisibleButtons + 1;
        }
        return this.currentPage - 1;
      },
      endPage() {
        return Math.min(
          this.startPage + this.maxVisibleButtons - 1,
          this.totalPages
        );
      },
      pages() {
        var range = [];
        for (var i = this.startPage; i <= this.endPage; i += 1) {
          if (i > 0) {
            range.push({
              name: i,
              isDisabled: i === this.currentPage,
            });
          }
        }
        return range;
      },
      isInFirstPage() {
        return this.currentPage === 1;
      },
      isInLastPage() {
        return this.currentPage === this.totalPages;
      },
    },
    methods: {
      onClickFirstPage() {
        this.$emit("pagechanged", 1);
      },
      onClickPreviousPage() {
        this.$emit("pagechanged", this.currentPage - 1);
      },
      onClickPage(page) {
        this.$emit("pagechanged", page);
      },
      onClickNextPage() {
        this.$emit("pagechanged", this.currentPage + 1);
      },
      onClickLastPage() {
        this.$emit("pagechanged", this.totalPages);
      },
      isPageActive(page) {
        return this.currentPage === page;
      },
      beginNumber() {
        let num = 1;

        if (this.currentPage > 1) {
          if (this.currentPage == this.totalPages) {
            const viewed = this.perPage * (this.currentPage - 1);
            num = viewed + 1;
          } else {
            num = this.currentPage * this.perPage;
          }
        }

        return num;
      },
      middleNumber() {
        return this.currentPage < this.totalPages
          ? this.perPage * this.currentPage
          : this.total;
      },
    },
  };
</script>
