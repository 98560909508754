<template>
  <div
    :class="['form-group', 'select-none', customClass, {'mb-0': removeMb,'has-error': inputError}]"
  >
    <div v-if="showLabel">
      <label class="control-label">{{ label }}</label>
    </div>
    <div
      :class="[{'flex items-center justify-between' : customLink}]"
      v-for="(item, index) in options"
      :key="index"
    >
      <div
        :class="['flex', 'items-center', {'mb-1.5': index + 1 < options.length && options.length > 1, 'mb-1': (index + 1 == options.length && options.length > 1 && removeMb) }]"
      >
        <input
          class="checkbox h-4 w-4 text-indigo-600 focus:ring-indigo-500 focus:ring-opacity-50 border-gray-300 rounded"
          type="checkbox"
          :id="inputName + '_' + index"
          :name="inputName + '_' + index"
          :value="item.value"
          v-model="checked"
          :disabled="disabled"
          @change="validateSelect"
        />
        <label
          :class="['ml-2', 'block', 'text-sm', 'checkbox-label', {'font-medium': isChecked(item), 'disabled': disabled}]"
          :for="inputName + '_' + index"
        >
          <span v-if="customText">
            <slot></slot>
          </span>
          <span :for="inputName + '_' + index" v-else>{{ item.name }}</span>
        </label>
      </div>
      <div class="text-sm" v-if="customLink">
        <slot></slot>
      </div>
      <!-- <div
        class="hv-font-13 hv-grey-600 hv-margin-top-neg-10 hv-margin-left-25"
        v-if="subText !== ''"
      >{{subText}}</div>
      <div class="validation-messages" v-if="displayValidations && inputError">
        <span v-if="requiredError" class="text-danger">{{ label }} is required</span>
      </div>-->
    </div>
    <!-- <div class="hv-checkbox-inline-group" v-else>
      <span
        :class="['checkbox-inline', { 'checked' : isChecked(item)}]"
        v-for="(item, index) in options"
        :key="index"
      >
        <input
          type="checkbox"
          :id="inputName + '_' + index"
          :name="inputName + '_' + index"
          :value="item.value"
          v-model="checked"
          :disabled="disabled"
          @change="validateSelect"
        />
        <label :for="inputName + '_' + index">{{ item.name }}</label>
      </span>
      <div class="validation-messages-wrapper" v-if="displayValidations && inputError">
        <div class="validation-messages">
          <span v-if="requiredError" class="text-danger">{{ label }} is required</span>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
  export default {
    props: {
      label: {
        type: String,
        default: "Default Checkbox",
      },
      showLabel: {
        type: Boolean,
        default: false,
      },
      subText: {
        type: String,
        default: "",
      },
      customClass: {
        type: String,
        default: "",
      },
      inputName: {
        type: String,
        default: "defaultCheckbox",
      },
      inputValue: {
        type: [Boolean, Array],
        default: null,
      },
      removeMb: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Array,
        default: () => [
          { name: "Default Selection 1", value: 1 },
          { name: "Default Selection 2", value: 2 },
        ],
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      // inline: {
      //   type: Boolean,
      //   default: false
      // },
      required: {
        type: Boolean,
        default: true,
      },
      customText: {
        type: Boolean,
        default: false,
      },
      customLink: {
        type: Boolean,
        default: false,
      },
      displayValidations: {
        type: Boolean,
        default: true,
      },
      touchValidation: {
        type: Boolean,
        default: false,
      },
      initFilter: {
        type: Boolean,
        default: false,
      },
      resetChecked: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        checked: [],
        inputError: false,
        requiredError: false,
      };
    },
    mounted() {
      if (this.inputValue && this.options.length > 0) {
        if (this.options.length === 1) {
          if (typeof this.inputValue === "boolean") {
            if (this.inputValue) {
              this.checked.push(this.options[0].value);
            }
          }
        } else {
          if (Array.isArray(this.inputValue)) {
            for (let o = 0; o < this.inputValue.length; o++) {
              for (let i = 0; i < this.options.length; i++) {
                if (this.options[i].value === this.inputValue[o]) {
                  this.checked.push(this.options[i].value);
                  break;
                }
              }
            }
          }
        }
        if (this.initFilter) {
          this.validateSelect();
        }
        this.checkValidation();
      }
    },
    watch: {
      inputValue(newValue) {
        if (newValue !== null && this.options.length > 0) {
          if (this.options.length === 1) {
            if (typeof newValue === "boolean") {
              if (
                !(newValue && this.checked.length === 1) &&
                !(!newValue && this.checked.length === 0)
              ) {
                this.checked = []; //clearing for new update
                if (newValue) {
                  this.checked.push(this.options[0].value);
                }
              }
            }
          } else {
            if (Array.isArray(newValue)) {
              if (newValue !== this.checked) {
                this.checked = []; //clearing for new update
                for (let o = 0; o < newValue.length; o++) {
                  for (let i = 0; i < this.options.length; i++) {
                    if (this.options[i].value === newValue[o]) {
                      this.checked.push(this.options[i].value);
                      break;
                    }
                  }
                }
              }
            }
          }
          this.checkValidation();
        }
      },
      resetChecked(newValue) {
        if (newValue === true) {
          this.checked = [];
        }
      },
      touchValidation(newValue) {
        if (newValue === true) {
          this.checkValidation();
        }
      },
    },
    methods: {
      isChecked(item) {
        for (let i = 0; i < this.checked.length; i++) {
          if (this.checked[i] === item.value) {
            return true;
          }
        }
        return false;
      },
      checkRequired() {
        if (this.required) {
          if (this.checked.length > 0) {
            if (this.requiredError) {
              this.requiredError = false;
            }
          } else {
            this.requiredError = true;
          }
        }
      },
      validateSelect() {
        if (!this.disabled) {
          this.checkRequired();
          if (!this.requiredError) {
            if (this.inputError) {
              this.inputError = false;
            }
            if (this.options.length > 1) {
              this.$emit("output", this.checked);
            } else {
              this.$emit("output", !!(this.checked.length > 0));
            }
            this.$emit("valid", true);
          } else {
            this.inputError = true;
            this.$emit("valid", false);
          }
        }
      },
      checkValidation() {
        if (!this.disabled) {
          this.checkRequired(this.selected);
          if (this.requiredError) {
            if (!this.inputError) {
              this.inputError = true;
            }
            this.$emit("valid", false);
          } else {
            // is valid, get rid of errors
            if (this.inputError) {
              this.inputError = false;
            }
          }
        }
      },
    },
  };
</script>

