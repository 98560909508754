<template>
  <div
    id="app"
    :class="[
      'my-10 ',
      { 'border border-gray-200 px-4 py-6 sm:px-6': frame === true, 'bg-white': transparent !== true, 'bg-transparent': transparent === true },
    ]"
  >
    <div>
      <app-list :domain="domain" :api="api" :id="id" :uid="uid"></app-list>
    </div>
  </div>
</template>

<script>
  import "./assets/css/utility.css";
  import "./assets/css/tailwind.css";
  import List from "./components/List.vue";

  export default {
    name: "HV-Widget",
    props: {
      id: {
        type: String,
        default: "",
      },
      uid: {
        type: String,
        default: "",
      },
      frame: {
        type: Boolean,
        default: false,
      },
      transparent: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        env: "prod",
        domain: "https://hirevise.com/",
        api: "https://api.hirevise.com/",
      };
    },
    components: { appList: List },
    // computed: {
    //   domain() {
    //     switch (this.env) {
    //       case "dev":
    //         return "http://localhost:3000/";
    //       case "test":
    //         return "https://hirevise-staging.net/";
    //       case "prod":
    //         return "https://hirevise.com/";
    //       default:
    //         return "https://hirevise.com/";
    //     }
    //   },
    //   api() {
    //     switch (this.env) {
    //       case "dev":
    //         return "http://localhost:55050/";
    //       case "test":
    //         return "https://api.hirevise-staging.net/";
    //       case "prod":
    //         return "https://api.hirevise.com/";
    //       default:
    //         return "https://api.hirevise.com/";
    //     }
    //   },
    // },
  };
</script>
