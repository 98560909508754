<template>
  <div class="bg-gray-50 border border-gray-200 rounded-md mb-5 px-4 pt-5 pb-2 sm:px-6">
    <div class="mb-3">
      <div class="text-sm font-medium text-gray-800 mb-2">Type</div>
      <div class="pb-3 border-b border-gray-200">
        <div v-if="typeList.length > 0">
          <app-checkbox
            input-name="typeFilter"
            :show-label="false"
            :required="false"
            :inline="false"
            :remove-mb="true"
            :options="typeList"
            @output="typeFilter = $event"
          ></app-checkbox>
        </div>
        <div v-else>
          <app-checkbox
            input-name="typeFilter"
            :show-label="false"
            :required="false"
            :inline="false"
            :remove-mb="true"
            :disabled="true"
            :options="[{ name: 'No Available Types' }]"
          ></app-checkbox>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <div class="text-sm font-medium text-gray-800 mb-2">Experience</div>
      <div class="pb-3 border-b border-gray-200">
        <div v-if="expList.length > 0">
          <app-checkbox
            input-name="expFilter"
            :show-label="false"
            :required="false"
            :remove-mb="true"
            :inline="false"
            :options="expList"
            @output="expFilter = $event"
          ></app-checkbox>
        </div>
        <div v-else>
          <app-checkbox
            input-name="expFilter"
            :show-label="false"
            :required="false"
            :inline="false"
            :remove-mb="true"
            :disabled="true"
            :options="[{ name: 'No Available Experiences' }]"
          ></app-checkbox>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <div class="text-sm font-medium text-gray-800 mb-2">Location</div>
      <div class="pb-3 border-b border-gray-200">
        <div v-if="remoteList.length > 0">
          <app-checkbox
            input-name="remoteFilter"
            custom-class="mb-1.5"
            :show-label="false"
            :required="false"
            :inline="false"
            :options="[{ name: 'Remote', value: 'Remote' }]"
            @output="remoteFilter = $event"
          ></app-checkbox>
        </div>
        <div v-if="cityList.length > 0">
          <div v-for="(loc, index) in locList" :key="index">
            <app-checkbox
              :custom-class="'mb-1.5 chkstate chkstate' + index"
              :input-name="'stateFilter' + loc.state.name + index"
              :input-value="loc.state.selected"
              :show-label="false"
              :required="false"
              :inline="false"
              :options="[loc.state]"
              @output="setStateFilter(loc.cities, $event)"
            ></app-checkbox>
            <div v-for="(city, index) in loc.cities" :key="index">
              <app-checkbox
                custom-class="chkcity mb-1.5 ml-6 pr-6"
                :input-value="getToggleValue(city.name)"
                :input-name="'cityFilter' + city.name + index"
                :show-label="false"
                :required="false"
                :inline="false"
                :options="[city]"
                @output="setCityFilter(city, $event)"
              ></app-checkbox>
            </div>
          </div>
          <div
            class="hv-link text-xs mt-2.5"
            @click="showMoreLoc = !showMoreLoc"
            v-if="exceedCountLoc"
          >
            {{
            showMoreLoc ? 'Show Less' : 'Show All'
            }}
          </div>
        </div>
        <div v-else>
          <app-checkbox
            input-name="locationFilter"
            :show-label="false"
            :required="false"
            :remove-mb="true"
            :inline="false"
            :disabled="true"
            :options="[{ name: 'No Available Locations' }]"
          ></app-checkbox>
        </div>
      </div>
    </div>

    <div class="mb-3">
      <div class="text-sm font-medium text-gray-800 mb-2">Category</div>
      <div>
        <div v-if="catList.length > 0">
          <app-checkbox
            input-name="catFilter"
            :show-label="false"
            :required="false"
            :remove-mb="true"
            :inline="false"
            :options="catList"
            @output="catFilter = $event"
          ></app-checkbox>

          <div
            class="hv-link text-xs mt-2.5"
            @click="showMoreCat = !showMoreCat"
            v-if="exceedCountCat"
          >
            {{
            showMoreCat ? 'Show Less' : 'Show All'
            }}
          </div>
        </div>
        <div v-else>
          <app-checkbox
            input-name="catFilter"
            :show-label="false"
            :required="false"
            :remove-mb="true"
            :inline="false"
            :disabled="true"
            :options="[{ name: 'No Available Categories' }]"
          ></app-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Checkbox from "./Checkbox.vue";
  import locationData from "../data/locations.json";

  export default {
    props: {
      itemList: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        locations: locationData.locations,
        expFilter: [],
        catFilter: [],
        typeFilter: [],
        remoteFilter: false,
        cityFilter: [],
        cityToggleList: [],
        exceedCountLoc: false,
        showMoreLoc: false,
        exceedCountCat: false,
        showMoreCat: false,
      };
    },
    components: {
      appCheckbox: Checkbox,
    },
    watch: {
      filteredList: {
        handler(newValue) {
          if (newValue) {
            this.$emit("output", newValue);
          }
        },
        immediate: true,
      },
    },
    /*eslint-disable */
    computed: {
      filteredList() {
        if (this.itemList.length > 0) {
          let returnList = this.itemList;
          if (this.typeFilter.length > 0) {
            returnList = returnList.filter((item) => {
              for (let i = 0; i < this.typeFilter.length; i++) {
                const e = this.typeFilter[i];
                if (item.type === e) {
                  return item;
                }
              }
            });
          }
          if (this.expFilter.length > 0) {
            returnList = returnList.filter((item) => {
              for (let i = 0; i < this.expFilter.length; i++) {
                const e = this.expFilter[i];
                if (item.experience === e) {
                  return item;
                }
              }
            });
          }
          if (this.catFilter.length > 0) {
            returnList = returnList.filter((item) => {
              for (let i = 0; i < this.catFilter.length; i++) {
                const e = this.catFilter[i];
                if (item.category === e) {
                  return item;
                }
              }
            });
          }
          if (this.cityFilter.length > 0 || this.remoteFilter) {
            returnList = returnList.filter((item) => {
              if (this.remoteFilter && item.remote) {
                return item;
              }
              for (let i = 0; i < this.cityFilter.length; i++) {
                const e = this.cityFilter[i];
                if (item.city === e) {
                  return item;
                }
              }
            });
          }
          return returnList;
        }
        return [];
      },
      typeList() {
        let options = [];
        if (this.itemList.length > 0) {
          const typeList = [
            ...new Set(this.itemList.map((item) => item.type)),
          ].sort();
          for (let i = 0; i < typeList.length; i++) {
            const type = typeList[i];
            options.push({ name: type, value: type });
          }
        }
        return options;
      },
      catList() {
        let options = [];
        if (this.itemList.length > 0) {
          const catList = [
            ...new Set(this.itemList.map((item) => item.category)),
          ].sort();
          for (let i = 0; i < catList.length; i++) {
            const cat = catList[i];
            options.push({ name: cat, value: cat });
          }
        }
        let count = 0;
        for (let i = 0; i < options.length; i++) {
          count++;
          if (count > 5 && !this.showMoreCat) {
            if (options.length > i) {
              this.exceedCountCat = true;
            }
            return options.slice(0, i);
          }
        }
        return options;
      },
      expList() {
        let options = [];
        if (this.itemList.length > 0) {
          const sortedList = this.itemList.sort((a, b) => {
            return a.experienceNum - b.experienceNum;
          });
          const expList = [...new Set(sortedList.map((item) => item.experience))];
          for (let i = 0; i < expList.length; i++) {
            const exp = expList[i];
            options.push({ name: exp, value: exp });
          }
        }
        return options;
      },
      cityList() {
        let options = [];
        if (this.itemList.length > 0) {
          const cities = this.itemList
            .map((item) => ({
              name: item.city,
              abbreviation: item.state,
              state: item.state,
            }))
            .filter((item) => item !== null);
          let uniqueCities = cities.filter(
            (elem, index, self) =>
              self.findIndex((t) => {
                if (t.name !== "") {
                  return t.name === elem.name && t.state === elem.state;
                }
              }) === index
          );
          for (let i = 0; i < uniqueCities.length; i++) {
            const element = uniqueCities[i];
            for (let o = 0; o < this.locations.length; o++) {
              const loc = this.locations[o];
              if (element.abbreviation === loc.abbreviation) {
                element.state = loc.state;
                break;
              }
            }
            options.push(element);
            this.cityToggleList.push({ name: element.name, selected: false });
          }
        }
        return options;
      },
      remoteList() {
        let options = [];
        if (this.itemList.length > 0) {
          const remoteList = this.itemList
            .map((item) => item.remote)
            .filter((item) => item === true);
          if (remoteList.length > 0) {
            options = remoteList;
          }
        }
        return options;
      },
      locList() {
        let options = [];
        if (this.cityList.length > 0) {
          let states = [];
          for (let i = 0; i < this.cityList.length; i++) {
            const city = this.cityList[i];
            let stateExists = false;
            if (states.length > 0) {
              for (let s = 0; s < states.length; s++) {
                const loc = states[s];
                if (city.state === loc.state.name) {
                  stateExists = true;
                  states[s].cities.push({
                    name: city.name,
                    value: city.name,
                  });
                  break;
                }
              }
            }
            if (!stateExists) {
              states.push({
                state: {
                  name: city.state,
                  value: city.state,
                },
                cities: [
                  {
                    name: city.name,
                    value: city.name,
                  },
                ],
              });
            }
          }
          if (states.length > 0) {
            states = states.sort((a, b) =>
              a.state.name.localeCompare(b.state.name)
            );
            for (let i = 0; i < states.length; i++) {
              states[i].cities = states[i].cities.sort((a, b) =>
                a.name.localeCompare(b.name)
              );
            }
            options = states;
          }
        }
        let count = 0;
        for (let i = 0; i < options.length; i++) {
          const loc = options[i];
          count++;
          for (let o = 0; o < loc.cities.length; o++) {
            count++;
            if (count > 5 && !this.showMoreLoc) {
              if (options.length > i) {
                this.exceedCountLoc = true;
              }
              return options.slice(0, i);
            }
          }
        }
        return options;
      },
    },
    /*eslint-enable */
    methods: {
      getToggleValue(city) {
        for (let i = 0; i < this.cityToggleList.length; ++i) {
          if (this.cityToggleList[i].name === city) {
            return this.cityToggleList[i].selected;
          }
        }
        return false;
      },
      setToggleValue(city, value) {
        for (let i = 0; i < this.cityToggleList.length; ++i) {
          if (this.cityToggleList[i].name === city) {
            this.cityToggleList[i].selected = value;
            break;
          }
        }
      },
      setCityFilter(city, $event) {
        this.setToggleValue(city.name, $event);
        if ($event === true) {
          this.addCityToFilter(city.name);
        } else {
          this.removeCityFromFilter(city.name);
        }
      },
      setStateFilter(cities, $event) {
        for (let i = 0; i < cities.length; i++) {
          if ($event === true) {
            this.addCityToFilter(cities[i].name);
          } else {
            this.removeCityFromFilter(cities[i].name);
          }
          this.setToggleValue(cities[i].name, $event);
        }
      },
      findCityIndex(city, list) {
        let index = -1;
        for (let i = 0; i < list.length; ++i) {
          if (list[i] === city) {
            index = i;
            break;
          }
        }
        return index;
      },
      addCityToFilter(city) {
        const index = this.findCityIndex(city, this.cityFilter);
        if (index === -1) {
          this.cityFilter.push(city);
        }
      },
      removeCityFromFilter(city) {
        const index = this.findCityIndex(city, this.cityFilter);
        if (index !== -1) {
          this.cityFilter.splice(index, 1);
        }
      },
    },
  };
</script>


