<template>
  <div class="px-4 sm:px-0" v-if="loaded">
    <div class="container grid grid-cols-1 lg:grid-cols-10 gap-x-4 md:gap-x-8" v-if="!error">
      <div class="hidden lg:block lg:col-span-3">
        <div
          class="bg-gray-50 border border-gray-200 rounded-md mb-5 px-4 py-5 sm:px-6 flex items-center justify-center"
          v-if="jobList.length > 1"
        >
          <div class="form-group mb-0">
            <input
              class="form-control"
              v-model="searchValue"
              type="text"
              placeholder="Search by Position Title"
              :disabled="filteredList.length < 1"
            />
          </div>
        </div>
        <app-filter :item-list="jobList" @output="filteredList = $event"></app-filter>
      </div>
      <div class="lg:col-span-7">
        <div class="lg:hidden p-4 sm:p-6 mb-5 rounded-md bg-gray-50 border border-gray-200">
          <div class="form-group mb-0">
            <input
              class="form-control"
              v-model="searchValue"
              type="text"
              placeholder="Search by Position Title"
              :disabled="filteredList.length < 1"
            />
          </div>
        </div>
        <div
          :class="['flex', 'mb-5', 'select-none', 'text-sm sm:text-base', {'justify-between': sortedList.length > 1}]"
        >
          <div>
            Showing:
            <span class="font-medium">
              <span>{{ sortedList.length }}</span>
              {{ sortedList.length === 1 ? 'position' : 'positions'}}
            </span>
          </div>
          <div class="flex items-center" v-if="sortedList.length > 1">
            Sort by:
            <div
              class="ml-2 hv-link underline cursor-pointer"
              @click="showSortOptions = !showSortOptions"
            >{{ sortTitle }}</div>
            <div class="relative" @click="changeSortOrder">
              <svg
                class="h-3 w-3 ml-1 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                v-show="sortOrder === 'asc'"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 13l-7 7-7-7m14-8l-7 7-7-7"
                />
              </svg>
              <svg
                class="h-3 w-3 ml-1 cursor-pointer"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                v-show="sortOrder === 'desc'"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 11l7-7 7 7M5 19l7-7 7 7"
                />
              </svg>
              <div
                class="origin-top-right z-30 absolute right-0 mt-3 w-36 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
                v-show="showSortOptions"
                v-on-clickaway="() => showSortOptions = false"
              >
                <ul class="py-1">
                  <li
                    :class="['block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer',{'font-semibold text-gray-800': sortKey === 'title'}]"
                    @click="sort('title')"
                  >Title</li>

                  <li
                    :class="['block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer',{'font-semibold text-gray-800': sortKey === 'city'}]"
                    @click="sort('city')"
                  >Location</li>
                  <li
                    :class="['block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer',{'font-semibold text-gray-800': sortKey === 'type'}]"
                    @click="sort('type')"
                  >Type</li>
                  <li
                    :class="['block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer',{'font-semibold text-gray-800': sortKey === 'type'}]"
                    @click="sort('category')"
                  >Category</li>
                  <li
                    :class="['block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 cursor-pointer',{'font-semibold text-gray-800': sortKey === 'dateCreated'}]"
                    @click="sort('dateCreated')"
                  >Date Posted</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div v-if="jobList.length > 0">
          <div class="space-y-2.5" v-if="filteredList.length > 0">
            <div v-for="(job, index) in sortedList" :key="index">
              <a
                :href="domain + id + '/positions/' + job.slug"
                target="_blank"
                rel="noopener noreferrer"
              >
                <div
                  class="relative p-4 sm:px-6 sm:grid grid-cols-2 items-center border border-gray-200 rounded-md hover:shadow-sm hover:bg-gray-50 hover:ring-2 hover:ring-indigo-600"
                >
                  <div class="text-gray-500">
                    <div class="text-base font-semibold text-indigo-600 mb-1">{{ job.title }}</div>
                    <div class="text-sm sm:flex sm:space-x-2 overflow-ellipsis">
                      <div class="mb-1 sm:mb-0">{{ job.type }}</div>
                      <div class="hidden sm:block text-gray-300">&#8226;</div>
                      <div class="mb-1 sm:mb-0">{{ job.experience }}</div>
                      <div class="hidden xl:block text-gray-300">&#8226;</div>
                      <div class="hidden xl:block">{{ job.category }}</div>
                      <div class="sm:hidden">{{ getLocation(job) }}</div>
                    </div>
                  </div>
                  <div class="hidden sm:flex justify-between items-center text-gray-500">
                    <div class="flex items-center text-sm">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h4 h-4 mr-2 text-gray-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                        />
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                      {{ getLocation(job) }}
                    </div>

                    <div>
                      <svg
                        class="h-5 w-5 text-gray-400"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  <div class="sm:hidden absolute-v-center right-0">
                    <svg
                      class="h-5 w-5 text-gray-400"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </a>
            </div>
            <div
              class="text-sm"
              v-if="sortedList.length === 0"
            >No positions matched your search criteria</div>
          </div>
          <div class="text-sm" v-else>No positions matched your filter criteria</div>
        </div>
        <div class="text-sm" v-else>There are no available positions.</div>
        <div class="mt-5" v-if="filteredList.length > size">
          <app-pagination
            :total-pages="pageCount"
            :total="listLength"
            :per-page="size"
            :public-page="true"
            :current-page="currentPage"
            @pagechanged="onPageChange"
          ></app-pagination>
        </div>
      </div>
    </div>
    <div class="container text-sm" v-else>There has been an unexpected loading error.</div>
  </div>
</template>

<script>
  import Filter from "./Filter.vue";
  import Pagination from "./Pagination.vue";
  import axios from "axios";
  import _ from "lodash";
  import { mixin as clickaway } from "vue-clickaway2";

  export default {
    props: {
      domain: {
        type: String,
        default: "",
      },
      api: {
        type: String,
        default: "",
      },
      id: {
        type: String,
        default: "",
      },
      uid: {
        type: String,
        default: "",
      },
    },
    data() {
      return {
        jobList: [],
        filteredList: [],
        copied: false,
        sortKey: "title",
        sortOrder: "asc",
        searchValue: "",
        currentPage: 1,
        size: 10,
        listLength: 0,
        pageCount: 1,
        showSortOptions: false,
        loaded: false,
        error: false,
      };
    },
    mixins: [clickaway],
    components: {
      appFilter: Filter,
      appPagination: Pagination,
    },
    mounted() {
      axios
        .get(this.api + "widget?id=" + this.id + "&uid=" + this.uid)
        .then((response) => {
          this.jobList = response.data.list;
          this.loaded = true;
        })
        .catch(() => {
          this.error = true;
          this.loaded = true;
        });
    },
    computed: {
      sortedList() {
        let returnList = [];
        if (this.filteredList.length > 0) {
          returnList = [...this.filteredList];
          if (this.searchValue.length > 0) {
            returnList = returnList.filter((item) => {
              return item.title
                .toLowerCase()
                .includes(this.searchValue.toLowerCase());
            });
          }
          this.getPageCount(returnList);
          let start = (this.currentPage - 1) * this.size;
          let end = start + this.size;

          if (this.sortKey === "title") {
            const titleSorter = (job) => job.title.toLowerCase();
            returnList = _.orderBy(returnList, titleSorter, this.sortOrder);
          } else if (this.sortKey === "city") {
            returnList = _.orderBy(
              returnList,
              ["city", "title"],
              [this.sortOrder, "asc"]
            );
          } else if (this.sortKey === "type") {
            returnList = _.orderBy(
              returnList,
              ["type", "title"],
              [this.sortOrder, "asc"]
            );
          } else if (this.sortKey === "category") {
            returnList = _.orderBy(
              returnList,
              ["category", "title"],
              [this.sortOrder, "asc"]
            );
          } else {
            returnList = _.orderBy(returnList, this.sortKey, this.sortOrder);
          }

          returnList = returnList.slice(start, end);
        }
        return returnList;
      },
      sortTitle() {
        switch (this.sortKey) {
          case "title":
            return "Title";
          case "type":
            return "Type";
          case "city":
            return "Location";
          case "category":
            return "Category";
          case "dateCreated":
            return "Date Created";
          default:
            return "Title";
        }
      },
    },
    methods: {
      createCssClass(name) {
        const className = name.replace(/[^A-Z0-9]+/gi, "_");
        return className;
      },
      getLocation(job) {
        if (job.hasLoc) {
          if (job.remote) {
            return job.city + ", " + job.state + " + Remote";
          }
          return job.city + ", " + job.state;
        }
        return "Remote/Telecommute";
      },
      getPageCount(list) {
        this.listLength = list.length;
        let s = this.size;
        let count = Math.ceil(this.listLength / s);
        if (count < 1) {
          count = 1;
        }
        this.pageCount = count;
        if (count < this.currentPage) {
          this.currentPage = count;
        }
      },
      onPageChange(page) {
        this.currentPage = page;
      },
      sort(key) {
        this.showSortOptions = false;
        if (key === this.sortKey) {
          this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
        } else {
          this.sortKey = key;
          this.sortOrder = "asc";
        }
        this.currentPage = 1;
      },
      changeSortOrder() {
        this.sortOrder = this.sortOrder == "asc" ? "desc" : "asc";
      },
    },
  };
</script>
